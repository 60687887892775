var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[(_vm.users)?[_c('addButton',{attrs:{"toRoute":"usersoverview/createedituser","label":_vm.$t('Add user')}}),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("Users"))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getFilterdUsers,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:`item.Email`,fn:function({ item }){return [_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"primary","size":"36"}},[_c('span',{staticClass:"white--text text-uppercase"},[_vm._v(_vm._s(_vm.$helpers.avatarLetters(item)))])]),_vm._v(" "+_vm._s(item.Email)+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('router-link',{attrs:{"to":{
              name: 'createEditCopyUser',
              params: {
                id: item._id.$oid,
                isCopy: true,
              },
            }}},[_c('v-btn',{attrs:{"depressed":"","disabled":item._id.$oid == 0,"icon":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-plus-box-multiple")])],1)],1),_c('router-link',{attrs:{"to":{ name: 'createEditUser', params: { id: item._id.$oid } }}},[_c('v-btn',{attrs:{"depressed":"","icon":"","color":"primaryorange"}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),_c('router-link',{attrs:{"to":{ name: 'deleteUser', params: { id: item._id.$oid } }}},[_c('v-btn',{attrs:{"depressed":"","icon":"","color":"primaryred"}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,true)})],1)]:(!_vm.$store.getters.error)?[_c('preloader')]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }