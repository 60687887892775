<template>
  <div class="mt-2">
    <template v-if="users">
      <addButton
        toRoute="usersoverview/createedituser"
        :label="$t('Add user')"
      />

      <v-card outlined>
        <v-card-title>
          {{ $t("Users") }}
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="getFilterdUsers"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:[`item.Email`]="{ item }">
            <v-avatar color="primary" size="36" class="mr-2">
              <span class="white--text text-uppercase">{{
                $helpers.avatarLetters(item)
              }}</span>
            </v-avatar>
            {{ item.Email }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <router-link
              :to="{
                name: 'createEditCopyUser',
                params: {
                  id: item._id.$oid,
                  isCopy: true,
                },
              }"
              ><v-btn
                depressed
                :disabled="item._id.$oid == 0"
                icon
                color="primary"
              >
                <v-icon>mdi-plus-box-multiple</v-icon>
              </v-btn>
            </router-link>
            <router-link
              :to="{ name: 'createEditUser', params: { id: item._id.$oid } }"
            >
              <v-btn depressed icon color="primaryorange">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </router-link>
            <router-link
              :to="{ name: 'deleteUser', params: { id: item._id.$oid } }"
            >
              <v-btn depressed icon color="primaryred">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </router-link>
          </template>
        </v-data-table>
      </v-card>
    </template>
    <template v-else-if="!$store.getters.error">
      <preloader></preloader>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: null,
    };
  },
  created() {
    this.getUsers();
  },
  computed: {
    getFilterdUsers() {
      if (this.$auth.userIsAdministrator) {
        return this.users;
      }
      const usersWithoutAdmins = this.users.filter(
        (user) => !user.Administrator
      );
      return usersWithoutAdmins;
    },

    headers() {
      return [
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "Email",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "right",
        },
      ];
    },
  },
  methods: {
    getUsers() {
      this.$request.get("/users", null, (res) => {
        if (res.success) {
          this.users = res.data;
        } else {
          const toaster = {
            message: res.message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        }
      });
    },
  },
};
</script>

<style>
</style>
